import FetchUtils from 'src/utils/fetch_utils';
import A11yDialog from 'a11y-dialog';

// For open modal window:
// document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: params}))
// Possible params:
// content - html content for modal window
// or
// url - path for fetching modal window content
// onComplete - callback after modal will be opened
// beforeClose - callback before modal will be closed
// classes - list of classes separated by space
// hideCloseButton - if true there won't be close icon and modal won't be closed by overlay click 
// fullscreen - if true show modal on full screen 
//
// For close:
// document.dispatchEvent(new CustomEvent('sm-modal:close'));
//
// Fired Events:
// sm-modal:opened
// sm-modal:closed
// 
// Listen to:
// 'html-updated' event to reinit all event bindings
//
// Add class js-modal-close-trigger for elements that should close modal window

class ModalWindows {
  #csrf = document.querySelector('meta[name=csrf-token]') ? document.querySelector('meta[name=csrf-token]').getAttributeNode('content').value : '';
  #openedModals = {};

  constructor() {
    this.handleOpenModal =  this.handleOpenModal.bind(this);
    this.handleOpenIframeModal = this.handleOpenIframeModal.bind(this);
  }

  loadScript(url, async = false, type = 'text/javascript') {
    return new Promise((resolve, reject) => {
      try {
        const scriptEle = document.createElement('script');
        scriptEle.type = type;
        scriptEle.async = async;
        scriptEle.src = url;

        scriptEle.addEventListener('load', () => {
          resolve({ status: true });
        });

        scriptEle.addEventListener('error', () => {
          reject({
            status: false,
            message: `Failed to load the script ${url}`
          });
        });

        document.body.appendChild(scriptEle);
      } catch (error) {
        reject(error);
      }
    });
  }

  executeScriptElements(containerElement) {
    const scriptElements = containerElement.querySelectorAll('script');

    let remoteScripts = [];
    [...scriptElements].filter(el => {return el.src;}).forEach((scriptElement) => {
      remoteScripts.push(this.loadScript(scriptElement.getAttribute('src')));
    });

    Promise.all(remoteScripts).then(() => {
      [...scriptElements].filter(el => {return !el.src;}).forEach((scriptElement) => {
        const clonedElement = document.createElement('script');
        Array.from(scriptElement.attributes).forEach((attribute) => {
          clonedElement.setAttribute(attribute.name, attribute.value);
        });
        clonedElement.text = scriptElement.text;
        scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
      });
      document.dispatchEvent(new CustomEvent('sm-modal:opened', {detail: {modalContainer: containerElement}}));
    });
  }

  fetchModalContent(url, params, modalId, onComplete, beforeClose, fullscreen, classes, hideCloseButton) {
    if (!document.getElementById(modalId)) {
      const h = this.getModalHtml(modalId, fullscreen, classes, hideCloseButton);
      document.querySelector('body').insertAdjacentHTML('beforeend', h);
    }
    this.showLoader();
    fetch(url, params).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.text();
      }).then(html => {
        this.hideLoader();
        document.getElementById(modalId).querySelector('.js-modal-content').innerHTML = html;
        this.executeScriptElements(document.getElementById(modalId));
        this.openModal(modalId, onComplete, beforeClose);
      }).catch((err) => {
        this.hideLoader();
        FetchUtils.handleResponseError(err);
      });
  }

  handleOpenModal(event) {
    event.preventDefault();
    event.stopPropagation();
    const link = event.currentTarget;
    const method = link.dataset.method || 'get';
    let headers = {async: true};
    if (method.toLowerCase() !== 'get') {
      headers['x-csrf-token'] = this.#csrf;
    }
    this.fetchModalContent(link.getAttribute('href'), {method, headers}, link.dataset.modalId);
    return false;
  }

  handleOpenIframeModal(event) {
    event.preventDefault();
    event.stopPropagation();
    const link = event.currentTarget;
    const content = `<iframe width="${(window.innerWidth * 0.9)}px" height="${(window.innerHeight * 0.9)}px" src="${link.href}"></iframe>`;
    document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content}}));
    return false;
  }

  bindLinkEvents(link) {
    link.removeEventListener('click', this.handleOpenModal);
    link.addEventListener('click', this.handleOpenModal);
  }

  initModalLink(link) {
    this.bindLinkEvents(link);
  }

  initModalIframeLink(link) {
    link.removeEventListener('click', this.handleOpenIframeModal);
    link.addEventListener('click', this.handleOpenIframeModal);
  }

  initModalsForContainer(wrapperSelector = 'body') {
    const wrapper = document.querySelector(wrapperSelector);
    if (wrapper) {
      wrapper.querySelectorAll('.js-modal-trigger').forEach((l) => {
        this.initModalLink(l);
      });

      wrapper.querySelectorAll('.js-modal-iframe').forEach((l) => {
        this.initModalIframeLink(l);
      });
    }
  }

  getModalHtml(modalId, fullscreen, classes, hideCloseButton) {
    return `<div aria-hidden="true" class="js-modal-wrap sm-modal-wrap ${classes || ''}">
      <div id='modal__loader-wrapper'  class='hidden js-modal-loader'><div class='sm-loader'></div></div>
      <div id='${modalId}' class='sm-modal js-sm-modal modal-slide' aria-hidden='true' role='alertdialog'>
        <div tabindex='-1' class="modal__overlay ${hideCloseButton ? '' : 'js-close-overlay'} js-modal-overlay">
          <div role='dialog' aria-modal='true' class='modal__container ${fullscreen ? 'fullscreen': ''}'>
            ${hideCloseButton ? '' : '<button class="modal__close js-modal-close-trigger js-close-modal-icon" aria-label="Close modal"></button>'}
            <div class='modal__content js-modal-content'>
            </div>
          </div>
        </div>
      </div></div>`;
  }

  initCloseLinks(modal) {
    let mouseDownTarget; 
    const container = this.#openedModals[modal.id].container;
    const beforeClose = this.#openedModals[modal.id].beforeClose;
    const closeIcon = document.querySelector('.js-close-modal-icon');
    if (closeIcon) {
      closeIcon.focus();
    }
    const overlay = modal.querySelector('.js-close-overlay');
      
    if (overlay) {
      overlay.addEventListener('mousedown', function (event) {
        mouseDownTarget = event.target;
      });

      const closeModalHandler = this.closeModal.bind(this);
      overlay.addEventListener('mouseup', function(event) {
        if (mouseDownTarget !== this) {
          return false;
        }
        event.preventDefault();
        closeModalHandler(container, beforeClose);
      });
    }

    modal.querySelectorAll('.js-modal-close-trigger').forEach(el => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        this.closeModal(container, beforeClose);
      });
    });
  }

  openModal(modalId, onComplete, beforeClose, hideCloseButton) {
    const modal = document.getElementById(modalId);
    const container = new A11yDialog(modal);
    
    this.#openedModals[modalId] = {container, onComplete, beforeClose, hideCloseButton};
    container.on('show', () => {
      this.adjustModalsHeight();

      this.initCloseLinks(modal);

      document.getElementById(modalId).querySelectorAll('.modal__container .js-modal-trigger').forEach((l) => {
        this.initModalLink(l);
      });

      if (onComplete && typeof(onComplete) === 'function') {
        onComplete();
      }
    });

    container.show();
  }

  showLoader() {
    document.querySelector('.js-modal-loader').classList.remove('hidden');
  }

  hideLoader() {
    document.querySelector('.js-modal-loader').classList.add('hidden');
  }

  initEscHandler() {
    $(document).keydown((event) => {
      if (event.keyCode === 27) {
        document.querySelectorAll('.js-sm-modal').forEach(m => {
          const hideCloseButton = this.#openedModals[m.id].hideCloseButton;
          if (hideCloseButton) {
            event.stopImmediatePropagation();
          } else {
            document.dispatchEvent(new CustomEvent('sm-modal:close', {detail: {modalId: m.id}}));
          }
        });
      }
    });
  }

  closeAllModals() {
    const modals = Object.values(this.#openedModals);
    modals.forEach(modalData => {
      this.closeModal(modalData.container, modalData.beforeClose);
    });
  }

  adjustModalsHeight() {
    const overlays = document.querySelectorAll('.js-modal-overlay');
    const topModal = (document.querySelector('.js-top-modal') && document.querySelector('.js-top-modal').closest('.js-modal-overlay'))
     || overlays[overlays.length - 1];
    if (topModal) {
      const padding = 20;
      const maxHeight = topModal.querySelector('.js-modal-content').clientHeight + padding;
      overlays.forEach(el => {
        el.style.maxHeight = `${maxHeight}px`;
        el.style.overflow = 'auto';
      });
    }
  }

  closeModal(modal, beforeClose) {
    if (beforeClose) {
      beforeClose();
    }
    modal.hide();
    modal.$el.closest('.js-modal-wrap').remove();
    modal.destroy();
    delete this.#openedModals[modal.$el.id];
    document.dispatchEvent(new CustomEvent('sm-modal:closed', {detail: {modalId: modal.id}}));
    this.adjustModalsHeight();
  }

  bindEvents() {
    this.initEscHandler();
    
    document.addEventListener('sm-modal:update-height', () => {
      this.adjustModalsHeight();
    });
    
    document.addEventListener('html-updated', (e) => {
      if (e.detail && !e.detail.keepOpened || !e.detail) {
        this.closeAllModals();
      } else {
        this.adjustModalsHeight();
        if (!e.detail.container || !e.detail) {
          document.querySelectorAll('.js-sm-modal').forEach((modal) => {
            this.initCloseLinks(modal);
          });
        }
      }
      
      const wrapper = e.detail ? e.detail.container : undefined;
      this.initModalsForContainer(wrapper);
    });
  
    document.addEventListener('dropdown:opened', () => {
      this.initModalsForContainer('.tippy-content .js-popover-content');
    });

    document.addEventListener('sm-modal:open', (e) => {
      const modalId = (e.detail && e.detail.modalId) ? e.detail.modalId : `sm-modal-${Math.random().toString(16).slice(2)}`;
      const content = e.detail.content;
      const url = e.detail.url;
      const onComplete = e.detail.onComplete;
      const beforeClose = e.detail.beforeClose;
      const fullscreen = e.detail.fullscreen;
      const classes = e.detail.classes;
      const hideCloseButton = e.detail.hideCloseButton ? JSON.parse(e.detail.hideCloseButton) : false;
        
      if (content) {
        if (!document.getElementById(modalId)) {
          const h = this.getModalHtml(modalId, fullscreen, classes, hideCloseButton);
          document.querySelector('body').insertAdjacentHTML('beforeend', h);
        }
        document.querySelector(`#${modalId} .js-modal-content`).innerHTML = content;
        this.executeScriptElements(document.getElementById(modalId));
        this.openModal(modalId, onComplete, beforeClose, hideCloseButton);
      } else if (url) {
        this.fetchModalContent(url, {headers: {async: true}}, modalId, onComplete, beforeClose, fullscreen, classes, hideCloseButton);
      }
    });

    document.addEventListener('sm-modal:close', (e) => {
      if (e.detail && e.detail.modalId) {
        const modalData = this.#openedModals[e.detail.modalId];
        this.closeModal(modalData.container, modalData.beforeClose);
      } else {
        const modals = Object.values(this.#openedModals);
        modals.forEach(modalData => {
          this.closeModal(modalData.container, modalData.beforeClose);
        });
      }
    });
  }

  init() {
    this.initModalsForContainer();

    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() { 
  new ModalWindows().init();
});