import 'quill/dist/quill.snow.css';
import './styles.scss';
import Quill from 'quill';
import './formats/divider-blot';
import './formats/placeholder-blot';
import './formats/break-blot';
import './formats/full-width-placeholder-blot';
import './plugins/insert_placeholder';
import './plugins/insert';

const Delta = Quill.import('delta');

class TemplateRichEditor {
  #editor;
  #templateId;
  #toolbar;

  init(fieldId, settings) {
    this.#templateId = fieldId;
    const wrap = document.getElementById(fieldId);
    const content = wrap.dataset.value;

    const placeholderIndex = settings.toolbar.indexOf('placeholder');
    if (placeholderIndex !== -1) {
      const placeholdersArray = JSON.parse(document.getElementById(fieldId).dataset.insertablePlaceholders);
      settings.toolbar[placeholderIndex] = {'placeholder': [...placeholdersArray].map((p) => p.value )};
    }

    const insertIndex = settings.toolbar.indexOf('insert');
    if (insertIndex !== -1) {
      settings.unusedMenuItems = settings.unusedMenuItems || [];
      const insertItems = ['Insert Line Break', 'Insert Paragraph Break', 'Insert Horizontal Line'].filter((el) => {
        return !settings.unusedMenuItems.includes(el);
      });
      settings.toolbar[insertIndex] = {'insert': insertItems};
    }

    const lineBreakMatcher = function() {
      var newDelta = new Delta();
      newDelta.insert({'smart-break': ''});
      return newDelta;
    };

    this.#editor = new Quill(`#${fieldId}`, {
      modules: {
        clipboard: {
          matchers: [
            ['BR', lineBreakMatcher] 
          ]
        },
        Insert: {},
        InsertPlaceholder: {},
        toolbar: {
          container: settings.toolbar,
          handlers: {
            'placeholder': () => {},
            'insert': () => {},
            'restoreDefaultTemplate': () => {this.restoreDefaultTemplate();}
          }
        }
      },
      theme: 'snow'
    });

    if (settings.blockedKeystrokes) {
      settings.blockedKeystrokes.forEach((blocked) => {
        this.#editor.keyboard.addBinding({ key: blocked }, () => {
          return false;
        });
      });
    }
    
    if (settings.editorHeight) {
      wrap.style.height = `${settings.editorHeight}px`;
    }

    if (content) {
      this.setContent(content);
    }

    this.#toolbar = wrap.previousElementSibling;
    
    this.bindEvents(fieldId);
    if (!settings.enable) {
      this.setShowMode();
    }

    document.getElementById(fieldId).dispatchEvent(new CustomEvent('sm-rich-editor:initialized'));
    return this;
  }

  restoreDefaultTemplate(withoutConfirmation = false) {
    if (withoutConfirmation || confirm('Restore default template? All content will be removed.')) {
      const defaultTemplate = document.querySelector('#' + this.#templateId).getAttribute('data-default-template');

      if (defaultTemplate) {
        this.setContent(defaultTemplate);
      }
      return true;
    } else {
      return false;
    }
  }

  setShowMode() {
    this.#toolbar.classList.add('disabled');
    this.#editor.enable(false);
  }

  setEditMode() {
    this.#toolbar.classList.remove('disabled');
    this.#editor.enable();
  }

  resetContent() {
    var oldContent = document.querySelector('#' + this.#templateId).getAttribute('data-persistent-value');
    if (!oldContent) {
      oldContent = document.querySelector('#' + this.#templateId).dataset.value;
    }
    this.setContent(oldContent);
  }

  // Replaces {{PlACEHOLDER_VALUE}} with
  // <span class='inserted-placeholder full-width-placeholder'>Placeholder Name</span>
  // and
  // replaces {PlACEHOLDER_VALUE} with
  // <span class='inserted-placeholder'>Placeholder Name</span>
  decorateContent(html) {
    const placeholderModule = this.#editor.getModule('InsertPlaceholder');
    if (placeholderModule && placeholderModule.insertablePlaceholders.length > 0) {
      var placeholders = html.match(/{{?(.+?)}}?/g);
      if (!placeholders) { return html; }
  
      for(var i = 0; i < placeholders.length; i++) {
        var placeholderValue = placeholders[i].replace(/{|}/g, '');
  
        var placeholderName = placeholderModule.placeholderNameByValue[placeholderValue];
        if (!placeholderName) { // Does not look like correct placeholder. Skip it.
          continue;
        }
  
        var toReplace = null;
  
        if (placeholderModule.fullWidthPlaceholders.indexOf(placeholderValue) >= 0) {
          var leftWrapper = '<span contenteditable="false" class="full-width-placeholder">';
          var rightWrapper = '</span>';
        } else {
          // For inline placeholders we need to handle additionally cases:
  
          // 1. Placeholder is at the beginning of the line:
          //    We should not add space before the placeholder.
          // 2. Placeholder has punctuation mark right after it:
          //    We should not add space after the placeholder.
          toReplace = '<p>{{?' + placeholderValue + '}}?(?=[.,;:?])';
          toReplace = new RegExp(toReplace, 'g');
          var leftWrapper = '<p><span contenteditable="false" class="inserted-placeholder">';// eslint-disable-line
          var rightWrapper = '</span>';// eslint-disable-line
          html = html.replace(toReplace, leftWrapper + placeholderName + rightWrapper);
  
          toReplace = '<p>{{?' + placeholderValue + '}}?\\s*';
          toReplace = new RegExp(toReplace, 'g');
          leftWrapper = '<p><span contenteditable="false" class="inserted-placeholder">';
          rightWrapper = '</span>&nbsp;';
          html = html.replace(toReplace, leftWrapper + placeholderName + rightWrapper);
  
          toReplace = '\\s*{{?' + placeholderValue + '}}?(?=[.,;:?])';
          toReplace = new RegExp(toReplace, 'g');
          leftWrapper = '&nbsp;<span contenteditable="false" class="inserted-placeholder">';
          rightWrapper = '</span>';
          html = html.replace(toReplace, leftWrapper + placeholderName + rightWrapper);
  
          // Default
          leftWrapper = '&nbsp;<span contenteditable="false" class="inserted-placeholder">';
          rightWrapper = '</span>&nbsp;';
        }
  
        toReplace = '\\s*{{?' + placeholderValue + '}}?\\s*';
        toReplace = new RegExp(toReplace, 'g');
        html = html.replace(toReplace, leftWrapper + placeholderName + rightWrapper);
      }
    }
    
    return html;
  }

  // Replaces <span class='inserted-placeholder'>Placeholder Name</span>
  // with {PlACEHOLDER_VALUE},
  // and
  // <span class='full-width-placeholder'>Placeholder Name</span>
  // with {{PlACEHOLDER_VALUE}}
  // Also removes all HTML tags inside { ... } and {{ ... }}.
  postprocessContent() {
    var content = this.#editor.getSemanticHTML();

    var regex = /<div.*?class=['"]full-width-placeholder['"].*?>(.*?)<\/div>(?:<br>)?/i;
    content   = this.postprocessPlaceholdersByRegex(content, regex, '{{', '}}');

    regex = /<span.*?class=['"]inserted-placeholder['"].*?>(.*?)<\/span>/i;
    content   = this.postprocessPlaceholdersByRegex(content, regex, '{', '}');

    // Replace &nbsp; with space.
    content = content.replace(/&nbsp;/g, ' ');

    // Remove spaces between {PLACEHOLDER} and punctuation marks.
    return content.replace(/({.+\})(\s+)(?=[.,;:])/g, '$1');
  }

  postprocessPlaceholdersByRegex(content, regex, leftWrapper, rightWrapper) {
    var regexGlobal = new RegExp(regex.source, 'gi');
    var placeholders = content.match(regexGlobal);
    if (placeholders) {
      for(var i = 0; i < placeholders.length; i++) {
        var toReplace = placeholders[i];
        var parsed = regex.exec(toReplace);
        if (!parsed) {
          continue;
        }
        var text = parsed[1].replace(/<.*?>/g, '');
        content = content.replace(toReplace, leftWrapper + this.#editor.getModule('InsertPlaceholder').placeholderValueByName[text] + rightWrapper);
      }
    }
    return content;
  }


  setContent(html) {
    html = this.decorateContent(html);
    this.#editor.setContents(this.#editor.clipboard.convert({html: html}));
  }

  bindEvents(fieldId) {
    const field = document.getElementById(fieldId);
    const form = field.closest('form');
    form.addEventListener('submit', () => {
      let textarea = document.createElement('textarea');
      textarea.classList.add('hidden');
      textarea.name = field.getAttribute('name');
      textarea.value = this.postprocessContent();
      form.appendChild(textarea);
    });
  }
}

window.SMTemplateRichEditor = TemplateRichEditor;